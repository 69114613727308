import { Breadcrumbs } from '../../layout/Breadcrumbs';

interface Crumb {
  label: string;
  to?: string;
}

interface HeaderSectionProps {
  mainHeader: string;
  secondaryHeader: string;
  crumbs: Crumb[];
}

const ClientInfoPageHeader = ({ mainHeader, secondaryHeader, crumbs }: HeaderSectionProps) => {
  return (
    <section className="relative w-full bg-cosmith-purple-300 bg-right bg-no-repeat rounded-xl bg-dots">
      <div className="absolute w-full h-[230px] bg-gradient rounded-xl sm:hidden"></div>
      <nav className="py-8 relative z-10 hidden sm:flex justify-center">
        <Breadcrumbs crumbs={crumbs} />
      </nav>
      <header className="flex flex-col justify-center w-full h-[230px] md:h-[174px] max-w-screen-xl items-center sm:items-start mx-auto relative pb-7 pt-4 px-0 sm:px-10 md:px-6 sm:text-left">
        <h1 className="text-[48px] font-semibold leading-[54px] text-cosmith-primary-900 tracking-[-0.02em] text-center pt-12 sm:pt-0">
          {mainHeader}
        </h1>
        <p className="text-[16px] leading-[24px] text-cosmith-primary-800 tracking-[-0.01em] mt-6 text-center">
          {secondaryHeader}
        </p>
      </header>
    </section>
  );
};

export default ClientInfoPageHeader;