import PageHeader from '../components/generic/header/ClientInfoPageHeader';
import { sections } from '../components/help-page/SectionsData';
import ClientInfoSections from '../components/help-page/ClientInfoSections';

const HelpPage = () => {
  const crumbs = [
    { label: 'Home', to: '/' },
    { label: 'Help Center', to: '/help' },
  ];

  return (
    <div className="w-full">
      <PageHeader 
        mainHeader="Help Articles" 
        secondaryHeader="Manage and organize your automations" 
        crumbs={crumbs} 
      />
      <div className="help-content p-2 sm:p-10 md:p-6 max-w-screen-xl m-auto mt-4 sm:mt-12">
        <ClientInfoSections sections={sections} />
      </div>
    </div>
  );
};

export default HelpPage;
