import { NavLink } from 'react-router-dom';

interface ILink {
  slug: string;
  title: string;
  isOpenInNewTab?: boolean;
}

interface INavBarListProps {
  title: string;
  links: ILink[];
}

export const NavbarList = ({ title, links }: INavBarListProps) => {
  //scroll to the top of the page after clicking link in the footer
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className='w-[171px] lg:w-[180px]'>
      <p className='text-white/60 mb-2'>{title}</p>
      <ul className='flex flex-col gap-2'>
        {links.map((link) => (
          <li key={link.title}>
            <NavLink
              to={link.slug}
              className='text-white hover:text-cosmith-lavanda-200 transition-colors duration-300'
              onClick={scrollToTop}
              target={link.isOpenInNewTab ? '_blank' : '_self'}
            >
              {link.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
