import { CrossIcon } from '../../icons/CrossIcon';


interface ICrossIconButtonProps {
  onClick: ()=>void;
  className?: string;
}

export default function ButtonCrossIcon ({ onClick }: ICrossIconButtonProps): JSX.Element {
  return (
    <button
      type='button'
      name='cross'
      onClick={onClick}
      className='bg-transparent border-none shadow-none p-3'
    >
     <CrossIcon clasName='fill-cosmith-dark-400 hover:fill-cosmith-lavanda-400'/>
    </button>
  );
}
