import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { ArrowDownIcon } from '../../icons';
import { useScreenSize } from '../../hooks';
import { Routes } from '../../util/constants';
import { useIsUserAuth } from '../../hooks/auth/useIsUserAuth';
import { ButtonSwitcher } from '../generic/switcher/ButtonSwitcher';

interface Props {
  productName?: string;
}

interface Tab {
  name: string;
  href: string;
  active?: boolean;
}

export const ProductNavigation = ({ productName }: Props): JSX.Element => {
  const screen = useScreenSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const isUserAuth = useIsUserAuth();

  const tabs = useMemo<Tab[]>(() => {
    const allTabs: Tab[] = [
      { name: 'Overview',        href: isUserAuth? Routes.myCategoryProductOverview(productName) : Routes.openCategoryProductOverview(productName) },
      { name: 'Installation',    href: isUserAuth? Routes.myCategoryProductInstallation(productName) : Routes.openCategoryProductInstallation(productName) },
      { name: 'Cases',           href: isUserAuth? Routes.myCategoryProductCases(productName) : Routes.openCategoryProductCases(productName) },
      // { name: 'Version history', href: isUserAuth? Routes.myCategoryProductVersions(productName) : Routes.openCategoryProductVersions(productName) }
    ];

    return allTabs.map((tab) => ({ ...tab, active: pathname === tab.href }));
  }, [productName, pathname]);

  const labels=useMemo(()=>{
    const labelsObj={
      first:{ title: 'Overview',   value: 'overview',    href: isUserAuth? Routes.myCategoryProductOverview(productName) : Routes.openCategoryProductOverview(productName) },
      second:{ title: 'Installation', value: 'installation',   href: isUserAuth? Routes.myCategoryProductInstallation(productName) : Routes.openCategoryProductInstallation(productName) },
      third:{ title: 'Cases', value:'cases',          href: isUserAuth? Routes.myCategoryProductCases(productName) : Routes.openCategoryProductCases(productName) },
    };
    return labelsObj;
  },[productName, pathname]);
  useEffect(() => {
    const parts = pathname.split('/').filter(Boolean);

    if (parts.length < 4) {
      navigate(tabs[0].href, { replace: true });
    }
  }, []);
  const [switchPosition, setSwitchPosition] = useState<string>(pathname.split('/')[4]??'overview');

  const currentTab = useMemo<Tab | null>(() => {
    return tabs.find((t) => t.href === pathname) || null;
  }, [tabs, pathname]);

  const isSelectorView = screen.lt.sm;

  return (
    <>
      {isSelectorView && (
        <div className="mx-4 border-t-2 pt-6">
          <div className="relative mt-2">
            <button type="button" onClick={() => setShowSelect(!showSelect)} className="flex justify-between items-center w-full h-12 cursor-pointer py-1.5 pl-4 pr-4 text-left text-cosmith-primary-900/60 border-solid border-2 rounded-lg sm:text-sm sm:leading-6">
              <span className="flex items-center">
                {currentTab && currentTab.name}
              </span>
              <span className="pointer-events-none flex items-center">
                <ArrowDownIcon />
              </span>
            </button>
            {showSelect &&
              <ul className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex={-1}>
                {tabs.map(tab => (
                  <li key={tab.name} className="relative cursor-pointer select-none py-2 pl-3 pr-9">
                    <NavLink
                      to={tab.href}
                      className={`${tab.active ? 'font-semibold' : 'font-normal'} ml-3 truncate`}
                      onClick={() => setShowSelect(false)}
                    >
                      {tab.name}
                    </NavLink>
                  </li>
                ))}
              </ul>}
          </div>
        </div>
      )}

      {!isSelectorView && (
        <ButtonSwitcher labels={labels} switchPosition={switchPosition}searchWord='' setSwitchPosition={setSwitchPosition} />
      )}
    </>
  );
};
