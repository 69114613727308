import { ReactComponent as ArrowDown } from '../../icons/ArrowDownSmall.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Article {
  id: string;
  title: string;
}

interface Section {
  id: string;
  title: string;
  articles: Article[];
}

interface ClientInfoSectionsProps {
  sections: Section[];
}

const DEFAULT_ARTICLES_TO_SHOW = 3;

const ClientInfoSections = ({ sections }: ClientInfoSectionsProps) => {
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({});

  const toggleSection = (sectionId: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  return (
    <section>
      {sections.map((section) => {
        const isExpanded = expandedSections[section.id] || false;
        const articlesToShow = isExpanded
          ? section.articles
          : section.articles.slice(0, DEFAULT_ARTICLES_TO_SHOW);

        return (
          <article key={section.id} className="mb-12">
            <header>
              <h2 className="text-[24px] font-semibold leading-[32px] tracking-[-0.016em] text-cosmith-primary-900 mb-12">
                {section.title}
              </h2>
            </header>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-4">
              {articlesToShow.map((article) => (
                <Link
                key={article.id}
                to={`/help/article/${article.id}`}
                className="text-[16px] leading-[24px] tracking-[-0.01em] text-cosmith-primary-900 opacity-60 mb-4 sm:font-bold"
                >
                  {article.title}
                </Link>
              ))}
            </div>
            {section.articles.length > DEFAULT_ARTICLES_TO_SHOW && (
              <button
                onClick={() => toggleSection(section.id)}
                className="flex items-center justify-center text-[16px] font-medium leading-[24px] tracking-[-0.01em] text-cosmith-primary-900 opacity-60 mt-6"
              >
                {isExpanded ? 'Show less' : 'See all articles'}
                <span
                  className={`ml-2 transform transition-transform duration-300 ${
                    isExpanded ? 'rotate-180' : ''
                  }`}
                >
                  <ArrowDown />
                </span>
              </button>
            )}
          </article>
        );
      })}
    </section>
  );
};

export default ClientInfoSections;
