import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './SlideSwitcher.module.css';

interface ILabels {
  first: { title: string; value: string; href?:string };
  second: { title: string; value: string; href?:string};
  third: { title: string; value: string; href?:string };
}

interface IButtonSwitcherProps {
  switchPosition: string;
  setSwitchPosition: (switchPosition: string) => void;
  labels: ILabels;
  searchWord: string;
}

export const ButtonSwitcher = ({
  switchPosition,
  setSwitchPosition,
  labels,
  searchWord,
}: IButtonSwitcherProps) => {
  const [animation, setAnimation] = useState<string>('startup');
  const location = useLocation();
  
  function getKeyByValue ( obj:ILabels, value:string) {
    return Object.entries(obj).find(([, val]) => val.value === value)?.[0];
  }
  const getSwitchAnimation = (newPosition: string, currentPosition: string) => {
    if (
      newPosition === labels.second.value &&
      currentPosition === labels.first.value
    ) {
      return 'first_to_second';
    } else if (
      newPosition === labels.third.value &&
      currentPosition === labels.second.value
    ) {
      return 'second_to_third';
    } else if (
      newPosition === labels.second.value &&
      currentPosition === labels.third.value
    ) {
      return 'third_to_second';
    } else if (
      newPosition === labels.first.value &&
      currentPosition === labels.second.value
    ) {
      return 'second_to_first';
    } else if (
      newPosition === labels.third.value &&
      currentPosition === labels.first.value
    ) {
      return 'first_to_third';
    } else if (
      newPosition === labels.first.value &&
      currentPosition === labels.third.value
    ) {
      return 'third_to_first';
    }
    return 'sfirst_to_second';
  };

  const setSwitchAnimation = (value: string) => {
    const animationClass = getSwitchAnimation(value, switchPosition);
    setAnimation(animationClass);
    setSwitchPosition(value);
  };

  const onTagClick = (option: string) => {
    setSwitchPosition(option);
    setSwitchAnimation(option);
  };

  return (
    <div className="flex justify-center">
      <div className="relative hidden lg:inline-block align-middle w-[723px] h-[50px] rounded-3xl bg-cosmith-primary-50">
      <div
          className={`${styles.switch}  ${styles[animation]} ${
            styles[`${getKeyByValue(labels,switchPosition)}_position`]
          }`}
        ></div>
        <NavLink
          to={labels.first.href?labels.first.href:{
            pathname: location.pathname,
            search: `?${searchWord}=${labels.first.value}`,
          }}
          className={`${styles.label} ${styles.first_label} ${
            getKeyByValue(labels,switchPosition) === 'first' && styles.label_checked
          }`}
          onClick={() => onTagClick(labels.first.value)}
        >
          <p className="font-medium leading-6 tracking-[-0.01rem]">
            {labels.first.title}
          </p>
        </NavLink>
        <NavLink
          to={labels.second.href?labels.second.href:{
            pathname: location.pathname,
            search: `?${searchWord}=${labels.second.value}`,
          }}
          className={`${styles.label} ${styles.second_label} ${
            getKeyByValue(labels,switchPosition) === 'second' && styles.label_checked
          }`}
          onClick={() => onTagClick(labels.second.value)}
        >
          <p className="font-medium leading-6 tracking-[-0.01rem]">
            {labels.second.title}
          </p>
        </NavLink>

        <NavLink
          to={labels.third.href?labels.third.href:{
            pathname: location.pathname,
            search: `?${searchWord}=${labels.third.value}`,
          }}
          className={`${styles.label} ${styles.third_label} ${
            getKeyByValue(labels,switchPosition) === 'third' && styles.label_checked
          }`}
          onClick={() => onTagClick(labels.third.value)}
        >
          <p className="font-medium leading-6 tracking-[-0.01rem]">
            {labels.third.title}
          </p>
        </NavLink>
      </div>
    </div>
  );
};
