import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SetterOrUpdater } from 'recoil';
import ButtonCrossIcon from './ButtonCrossIcon';

type ValueType = string;

export interface SelectOptions {
  id: string;
  value: ValueType;
  label: string;
  placeholder?: boolean;
}

type OnChangeHandlerFn = (value: ValueType) => void;
type OnChangeHandlerSetter = SetterOrUpdater<ValueType>;

interface ISelectProps {
  label?: string;
  placeholder?: string;
  options: SelectOptions[];
  value: ValueType;
  onChange: OnChangeHandlerFn | OnChangeHandlerSetter;
  className?: string;
}

export function Select ({
  label,
  options,
  value,
  onChange,
  className = '',
  placeholder = 'Select option',
}: ISelectProps): JSX.Element {
  const selected = options.find((o) => value && o.value === value);

  const visibleValue = selected ? selected.label : placeholder;

  return (
    <div className={className}>
      <Listbox value={value} onChange={onChange}>
        <div className="relative">
          <div className="mb-2 text-sm text-cosmith-primary-900/60">
            {label}
          </div>
          <div className={`flex items-center justify-between ${selected ? 'text-cosmith-primary-900' : 'text-cosmith-primary-900/60'}
            rounded-lg shadow
            py-3 pl-4 h-12
            border-solid border border-cosmith-primary-900/10`}>
          <Listbox.Button
            className={`
            relative w-full cursor-default
            text-left
            
          `}
          >
            <span className="block truncate">{visibleValue}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            </span>
          </Listbox.Button>
          {value!==''&& <ButtonCrossIcon  onClick={()=>onChange('')}/>}
          
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 mt-1 space-y-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((item) => (
                <Listbox.Option
                  key={item.id}
                  className={() =>
                    `relative cursor-default select-none py-2 pl-4 pr-4 hover:bg-cosmith-primary-50 ${item.placeholder? 'text-cosmith-primary-900/60 text-sm' : 'text-base text-cosmith-primary-900'}`
                  }
                  value={item.value}
                >
                  {({ selected: isSelected }) => (
                    <span
                      className={`block truncate ${isSelected ? 'font-medium' : 'font-normal'}`}
                    >
                      {item.label}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
        
          </Transition>
        </div>
      </Listbox>
      
    </div>
  );
}
